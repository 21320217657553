<template>
  <div class="dashboard-kol">
    <div class="filter-section">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-select v-model="sortBy" placeholder="按执行行DDL降序">
            <el-option label="按执行行DDL降序" value="ddl-desc"></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select v-model="projectType" placeholder="负责项目" clearable>
            <el-option label="全部" value=""></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-col>
      </el-row>
    </div>

    <div class="action-section">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="12">
          <el-tabs v-model="activeTab">
            <el-tab-pane label="执行项目" name="executing"></el-tab-pane>
            <el-tab-pane label="提案项目" name="proposal"></el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button type="primary" @click="handleExport">导出</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="statistics-section">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-card shadow="never">
            <div class="stat-item">
              <div class="stat-label">签约金额(元)</div>
              <div class="stat-value">{{ contractAmount }}</div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="never">
            <div class="stat-item">
              <div class="stat-label">执行进度</div>
              <div class="stat-value">{{ executionProgress }}</div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="never">
            <div class="stat-item">
              <div class="stat-label">执行达人</div>
              <div class="stat-value">{{ kolCount }}</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div class="table-section">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="customerName" label="客户名称" width="180"></el-table-column>
        <el-table-column prop="contractAmount" label="签约金额" width="150"></el-table-column>
        <el-table-column prop="projectOwner" label="项目负责人" width="120"></el-table-column>
        <el-table-column prop="executionProgress" label="执行进度" width="120"></el-table-column>
        <el-table-column prop="kolCount" label="执行达人" width="120"></el-table-column>
        <el-table-column prop="executionDDL" label="执行DDL" width="120"></el-table-column>
        <el-table-column prop="invoicedAmount" label="已开票金额" width="120"></el-table-column>
        <el-table-column prop="receivedAmount" label="已收款金额" width="120"></el-table-column>
        <el-table-column prop="projectStage" label="项目阶段" width="120"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleView(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardKOL',
  data () {
    return {
      sortBy: 'ddl-desc',
      projectType: '',
      dateRange: [],
      activeTab: 'executing',
      contractAmount: '0.00',
      executionProgress: '0/0',
      kolCount: '0/0',
      tableData: []
    }
  },
  methods: {
    handleExport () {
      // 处理导出
    },
    handleView (row) {
      // 处理查看详情
      console.log('查看行:', row)
    }
  }
}
</script>

<style scoped>
.dashboard-kol {
  padding: 24px;
}

.filter-section {
  margin-bottom: 24px;
}

.action-section {
  margin-bottom: 24px;
}

.statistics-section {
  margin-bottom: 24px;
}

.stat-item {
  text-align: center;
  padding: 20px;
}

.stat-label {
  font-size: 14px;
  color: #909399;
  margin-bottom: 8px;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #303133;
}

.table-section {
  background: #fff;
  padding: 24px;
  border-radius: 4px;
}

.el-select {
  width: 100%;
}

.el-date-picker {
  width: 100%;
}
</style>